import React, { FunctionComponent, ReactElement } from "react"
import ReactDOM from "react-dom"
import cn from "clsx"

import styles from "./close-onboarding-hint.module.scss"

type TCloseOnboardingHintProps = {
  transparentPosition: any
  isTransparentClickEnabled: boolean
  onClick: (event) => void
  onTransparentClick: () => void
  isClickableContent?: boolean
  zIndex?: number
}

const CloseOnboardingHint: FunctionComponent<TCloseOnboardingHintProps> = ({
  onClick,
  onTransparentClick,
  transparentPosition,
  isTransparentClickEnabled,
  isClickableContent,
  zIndex = 100,
}): ReactElement => {
  const [isClient, setIsClient] = React.useState(false)

  const button: ReactElement = (
    <>
      <div
        className={cn(styles["root"], { [styles["clickable-content"]]: isClickableContent })}
        onClick={(event) => onClick(event)}
        style={{ zIndex: isClickableContent && zIndex }}
      ></div>
      {isTransparentClickEnabled && (
        <div
          style={{ ...transparentPosition }}
          onClick={() => onTransparentClick()}
          className={styles["transparent"]}
        ></div>
      )}
    </>
  )

  React.useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient ? ReactDOM.createPortal(button, document.getElementById("close-onboarding-portal")) : <></>
}

export { CloseOnboardingHint }
