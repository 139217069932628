import { useEffect, useRef } from "react"
import { useRouter } from "next/router"

import { useGetFavoritesList } from "@app/api/favorites/queries"
import { trackYandexMetrikaAction } from "@app/features/analytics/utils"
import { useAuthorizationModal } from "@app/features/user/stores"
import { useAuthorization } from "@app/features/user/stores"
import { checkAccessToken } from "@app/features/user/utils"

export const useLike = (galleryId: string) => {
  const router = useRouter()
  const { showAuthorizationModal } = useAuthorizationModal()
  const { isUserAuthorized } = useAuthorization()
  const { data: favoritesList } = useGetFavoritesList(galleryId, { query: { enabled: isUserAuthorized } })

  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const redirectToDefaultFavoritesListPage = (id: string) => {
    if (id) {
      router.push(`/favorites-list/${id}`)
    }
  }

  const handleLikeClick = () => {
    trackYandexMetrikaAction("click-header-favorites-desktop")

    if (!checkAccessToken()) {
      showAuthorizationModal()

      return
    }

    if (favoritesList?.data && isMounted.current) {
      redirectToDefaultFavoritesListPage(favoritesList.data.id)
    }
  }

  return { handleLikeClick }
}
