import React from "react"
import { useRouter } from "next/router"
import cx from "clsx"

import { REQUEST_TYPE } from "@app/api/constants"
import { QA_CLASS_NAMES } from "@app/constants"
import { useGallery } from "@app/contexts"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { useAuthorization, useAuthorizationModal, useHidingPhotosAuth } from "@app/features/user/stores"
import { SignOut } from "@app/ui/icons"
import { ModalLine } from "@app/ui/modal-line"
import { Tooltip } from "@app/ui/tooltip"
import { fetchGallery } from "features/gallery/requests"
import { signOutUser } from "features/user/utils"

function AuthClientSignOut({
  tooltip,
  tooltipPosition,
  modalText,
  modalApprove,
  modalCancel,
  modalPosition,
  isMobileType,
  buttonClassName,
  iconClassName,
  strokeLight,
  strokeDark,
  fillLight,
  fillDark,
  fillSecond,
  children,
}) {
  const { setUserAuthorization } = useAuthorization()
  const { setUserAuthorizationHidingPhotos } = useHidingPhotosAuth()
  const { isHidingPhotosSignOutModalVisible, hideSignOutModalHidingPhotos, showSignOutModalHidingPhotos } =
    useAuthorizationModal()

  const { setGallery } = useGallery()
  const router = useRouter()

  function handleClientAccessExit() {
    showSignOutModalHidingPhotos()
  }

  async function handleSignOut() {
    signOutUser()
    setUserAuthorization(false)
    setUserAuthorizationHidingPhotos(false)
    hideSignOutModalHidingPhotos()

    const slug = router.query.slug
    const domain = window.location.host
    const { gallery } = await fetchGallery({ requestType: REQUEST_TYPE.CLIENT, slug, domain, ctx: null })
    setGallery(gallery)
  }

  function handleCancel() {
    hideSignOutModalHidingPhotos()
  }

  return (
    <>
      <Tooltip position={tooltipPosition} text={tooltip}>
        <button className={cx(buttonClassName, QA_CLASS_NAMES.header.logout)} onClick={handleClientAccessExit}>
          <IconWrapper
            light={
              <SignOut
                width="21"
                height="21"
                stroke={strokeLight}
                fill={fillLight}
                fillSecond={fillSecond}
                className={iconClassName}
              />
            }
            dark={
              <SignOut
                width="21"
                height="21"
                stroke={strokeDark}
                fill={fillDark}
                fillSecond={fillSecond}
                className={iconClassName}
              />
            }
          />
          {children}
        </button>
      </Tooltip>
      <ModalLine
        open={isHidingPhotosSignOutModalVisible}
        text={modalText}
        approve={modalApprove}
        cancel={modalCancel}
        position={modalPosition}
        handleApprove={handleSignOut}
        handleCancel={handleCancel}
        isMobileType={isMobileType}
      />
    </>
  )
}

export { AuthClientSignOut }
