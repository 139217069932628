import React from "react"
import { Element as ScrollableAnchor } from "react-scroll"

import { OnboardingTour } from "@app/features/onboarding/components/onboarding-tour"
import { IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY, STEPS_CLASS_NAME_TOUR } from "@app/features/onboarding/constants"
import { visitsCountIncrement } from "@app/features/statistics"
import { DesktopActions, MobileMenu, Navigation, Scenes } from "features/gallery/components/header/components"
import { useMobileMenuHorizontalScroll } from "features/gallery/components/header/hooks"
import { Container } from "ui/container"
import { Logo } from "ui/logo"

import styles from "./header.module.scss"

function Header({
  scenes,
  cover,
  logo,
  logoAlt,
  photographerName,
  galleryId,
  galleryName,
  photographerSite,
  isWithoutScenes,
  isDownloadEnabled,
  isHasHidingMediaFile,
  isCanBeHidden,
}) {
  const { isMobileMenuScrollRight, mobileMenuRef, handleScrollMobileMenu } = useMobileMenuHorizontalScroll()

  const stepsClassNameTour = [STEPS_CLASS_NAME_TOUR.downloadAll.name]
  const localStorageKey = IS_FIRST_VISIT_MOBILE_LOCAL_STORAGE_KEY

  function unlockScroll() {
    const $body = document.body

    $body.classList.remove("lock-scroll")
    const scrollPosition = parseInt($body.getAttribute("data-scroll"))
    $body.removeAttribute("data-scroll")
    $body.style.removeProperty("top")

    window.scrollTo(0, scrollPosition)
  }

  return (
    <OnboardingTour
      stepsClassName={stepsClassNameTour}
      onClose={() => unlockScroll()}
      localStorageKey={localStorageKey}
    >
      <header className={styles["header"]} id="header">
        <ScrollableAnchor name="header">
          <Container className={styles["container"]}>
            <Navigation isMobileMenuScrollRight={isMobileMenuScrollRight} isWithoutScenes={isWithoutScenes}>
              <Logo
                logo={logo}
                logoAlt={logoAlt}
                photographerName={photographerName}
                photographerSite={photographerSite}
                onClick={() => {
                  visitsCountIncrement(galleryId)
                }}
                className={styles["logo"]}
              />

              <MobileMenu
                scenes={scenes}
                galleryId={galleryId}
                isWithoutScenes={isWithoutScenes}
                isDownloadEnabled={isDownloadEnabled}
                galleryName={galleryName}
              />
              <Scenes
                scenes={scenes}
                mobileMenuRef={mobileMenuRef}
                handleScrollMobileMenu={handleScrollMobileMenu}
                isWithoutScenes={isWithoutScenes}
                isHasHidingMediaFile={isHasHidingMediaFile}
                isCanBeHidden={isCanBeHidden}
              />
            </Navigation>
            <DesktopActions
              cover={cover}
              galleryId={galleryId}
              isDownloadEnabled={isDownloadEnabled}
              galleryName={galleryName}
            />
          </Container>
        </ScrollableAnchor>
      </header>
    </OnboardingTour>
  )
}

export { Header }
