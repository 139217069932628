import { useI18n } from "next-localization"
import clsx from "clsx"

import { COLORS, QA_CLASS_NAMES } from "@app/constants"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Like } from "ui/icons"

import { useLike } from "../../../hooks/use-like"

import styles from "./action-like.module.scss"

export const ActionLike = ({ galleryId, className }) => {
  const { t } = useI18n()
  const { handleLikeClick } = useLike(galleryId)

  return (
    <li className={className}>
      <button className={clsx(styles["button"], QA_CLASS_NAMES.header.favourites)} onClick={handleLikeClick}>
        <IconWrapper
          light={<Like width="18" height="16" stroke={COLORS.smokeScreen} className={styles["mark-icon"]} />}
          dark={<Like width="18" height="16" stroke={COLORS.nickel} className={styles["mark-icon"]} />}
        />
        {t("common.favorites")}
      </button>
    </li>
  )
}
