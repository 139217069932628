import type { FC } from "react"
import { useI18n } from "next-localization"
import clsx from "clsx"

import { COLORS, QA_CLASS_NAMES } from "@app/constants"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Like } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import { useLike } from "../../../hooks/use-like"

import styles from "./action-like.module.scss"

type Props = {
  galleryId: string
}

export const ActionLike: FC<Props> = ({ galleryId }) => {
  const { t } = useI18n()
  const { handleLikeClick } = useLike(galleryId)

  return (
    <li className={styles["action"]}>
      <Tooltip text={t("common.favorites")}>
        <button className={clsx(styles["button"], QA_CLASS_NAMES.header.favourites)} onClick={handleLikeClick}>
          <IconWrapper
            light={<Like width="18" height="18" fill="none" stroke={COLORS.black} />}
            dark={<Like width="18" height="18" fill="none" stroke={COLORS.white} />}
          />
        </button>
      </Tooltip>
    </li>
  )
}
