import React, { FunctionComponent, ReactElement } from "react"
import Floater from "react-floater"
import { InView } from "react-intersection-observer"

import { CloseOnboardingHint } from "../close-onboarding-hint"
import { OnboardingHintContent } from "../onboarding-hint-content/onboarding-hint-content"

import styles from "./onboarding-hint.module.scss"

type InteractiveOnboardingPopupProps = {
  children: JSX.Element | JSX.Element[] | ReactElement | ReactElement[] | string | string[] | any
  content: JSX.Element | JSX.Element[] | ReactElement | ReactElement[] | string | string[]
  id?: string
  offset?: string
  space?: string
  type?: "square" | "circle"
  arrowPosition?: {
    desktop?: "left" | "center" | "bottom-center" | "right" | "left-end" | "right-none"
    mobile?: "left" | "center" | "bottom-center" | "right" | "left-end" | "none"
  }
  visibleOnView?: boolean
  inViewMargin?: number
  allowDisplay?: boolean
  isMobileFixed?: boolean
  isTransparentClickEnabled?: boolean
  delay?: number
  onClose?: () => void
  onTransparentClick?: () => void
  withImageType?: boolean
  canVisible?: boolean
  isClickableContent?: boolean
  disableFlip?: boolean
  position?: "top" | "bottom"
  zIndex?: number
}

const OnboardingHint: FunctionComponent<InteractiveOnboardingPopupProps> = ({
  children,
  content,
  id = "",
  offset = "15px",
  type = "square",
  space = "0px",
  arrowPosition = {
    desktop: "center",
    mobile: "center",
  },
  visibleOnView = false,
  inViewMargin = 240,
  allowDisplay = true,
  isMobileFixed = false,
  position = "top",
  delay = 0,
  onClose = () => {},
  onTransparentClick = () => {},
  isTransparentClickEnabled = false,
  withImageType = false,
  canVisible = true,
  isClickableContent = false,
  disableFlip = true,
  zIndex = 100,
}): ReactElement => {
  const [isVisible, setIsVisible] = React.useState(false)
  const contentRef = React.useRef(null)
  const [referenceElement, setReferenceElement] = React.useState(null)
  const [isInView, setIsInView] = React.useState(false)

  const handleGetPopover = (popper, origin) => {
    const { reference } = popper.state.elements
    setReferenceElement(reference)
  }

  const closeHint = () => {
    setIsVisible(false)
    unlockScroll()
    localStorage.setItem(`${id}`, "completed")
    onClose()
  }

  const handleCloseHint = (event) => {
    event.preventDefault()
    event.stopPropagation()
    closeHint()
  }

  React.useEffect(() => {
    if (!visibleOnView) {
      setTimeout(() => {
        const isAllowDisplay = typeof window !== "undefined" && localStorage.getItem(`${id}`) !== "completed"
        isAllowDisplay && setIsVisible(true)
      }, delay)
    }
  }, [])

  React.useEffect(() => {
    if (!referenceElement) return
    if (isVisible) {
      referenceElement?.classList.add(styles["overlay"])
      referenceElement?.classList.add(styles[type])
      disableFlip && document.getElementById("react-floater-portal").classList.add("g-tooltip-disable-flip")
      referenceElement?.style.setProperty("--offset", `${offset}`)
      contentRef?.current?.style.setProperty("--space", `${space}`)
    } else {
      referenceElement?.classList.remove(styles["overlay"])
      referenceElement?.classList.remove(styles[type])
      document.getElementById("react-floater-portal").classList.remove("g-tooltip-disable-flip")
    }
  }, [isVisible, referenceElement])

  const handleTransparentClick = () => {
    closeHint()
    onTransparentClick()
  }

  const lockScroll = () => {
    const $body = document.body

    $body.style.top = `-${window.scrollY}px`
    $body.setAttribute("data-scroll", `${window.scrollY}`)
    $body.classList.add("lock-scroll")

    if (isMobileFixed) {
      $body.classList.add("is-onboarding-mobile-fixed")
    }
  }

  const unlockScroll = () => {
    const $body = document.body

    $body.classList.remove("lock-scroll")
    const scrollPosition = parseInt($body.getAttribute("data-scroll"))
    $body.removeAttribute("data-scroll")
    $body.style.removeProperty("top")

    if (isMobileFixed) {
      // тк возвращается transform3d, и элементы прыгаю, пускаем удаление класса в конец очереди
      setTimeout(() => {
        $body.classList.remove("is-onboarding-mobile-fixed")
      }, 1000)
    }

    window.scrollTo(0, scrollPosition)
  }

  const handleVisibleHintArea = (inView) => {
    setIsInView(inView)

    if (canVisible && allowDisplay && inView && localStorage.getItem(`${id}`) !== "completed") {
      setIsVisible(true)
    }
  }

  React.useEffect(() => {
    isVisible && lockScroll()
  }, [isVisible])

  const rootMargin = `-${inViewMargin}px 0px`

  React.useEffect(() => {
    if (isInView && canVisible && localStorage.getItem(`${id}`) !== "completed") {
      setIsVisible(true)
    }
  }, [canVisible])

  return (
    <>
      {referenceElement && isVisible && (
        <CloseOnboardingHint
          transparentPosition={{
            top: referenceElement.getBoundingClientRect().top + window.scrollY,
            left: referenceElement.getBoundingClientRect().left,
            width: referenceElement.getBoundingClientRect().width,
            height: referenceElement.getBoundingClientRect().height,
          }}
          isTransparentClickEnabled={isTransparentClickEnabled}
          onTransparentClick={handleTransparentClick}
          onClick={handleCloseHint}
          isClickableContent={isClickableContent}
          zIndex={zIndex}
        />
      )}
      <Floater
        styles={{
          options: {
            zIndex: zIndex,
          },
          wrapper: {
            cursor: "inherit",
          },
          floater: {
            filter: "unset",
            height: "1px",
          },
        }}
        open={isVisible}
        hideArrow={true}
        disableFlip={true}
        getPopper={(popper, origin) => handleGetPopover(popper, origin)}
        placement="bottom"
        wrapperOptions={{ offset: 0, position: false }}
        component={
          <OnboardingHintContent
            ref={contentRef}
            referenceWidth={referenceElement?.getBoundingClientRect().width}
            arrowPosition={arrowPosition}
            withImageType={withImageType}
            position={position}
            onClick={isClickableContent && handleCloseHint}
          >
            {typeof content === "string" && <div dangerouslySetInnerHTML={{ __html: content as string }}></div>}
            {typeof content !== "string" && (content as ReactElement)}
          </OnboardingHintContent>
        }
      >
        {children}
      </Floater>
      {visibleOnView && (
        <InView as="div" rootMargin={rootMargin} onChange={(inView, event) => handleVisibleHintArea(inView)}></InView>
      )}
    </>
  )
}

export { OnboardingHint }
