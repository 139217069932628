import React from "react"
import cx from "clsx"

import { QA_CLASS_NAMES } from "@app/constants"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { useAuthorizationModal } from "@app/features/user/stores"
import { User } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

function AuthClientSignIn({
  tooltip,
  tooltipPosition,
  buttonClassName,
  iconClassName,
  strokeLight,
  strokeDark,
  fillLight,
  fillDark,
  children,
}) {
  const { showAuthorizationModalHidingPhotos } = useAuthorizationModal()

  function handleClientAccess() {
    showAuthorizationModalHidingPhotos()
  }

  return (
    <Tooltip position={tooltipPosition} text={tooltip}>
      <button className={cx(buttonClassName, QA_CLASS_NAMES.header.logout)} onClick={handleClientAccess}>
        <IconWrapper
          light={<User width="16" height="18" stroke={strokeLight} fill={fillLight} className={iconClassName} />}
          dark={<User width="16" height="18" stroke={strokeDark} fill={fillDark} className={iconClassName}></User>}
        />
        {children}
      </button>
    </Tooltip>
  )
}

export { AuthClientSignIn }
